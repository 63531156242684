import "./Chat.css";
import { useEffect, useState, useRef } from "react";
import Avatar from "react-avatar";
import { host } from "./enums"

const chatResponseUrl = `${host}/ask`

// The messages array will hold the chat messages
const messages = []

function Chat(props) {
  const audio = useRef();
  const [tempUsername, setTempUsername] = useState("");
  const [username, setUsername] = useState("");
  const [messageText, setMessageText] = useState("");
  const [sendEnabled, setSendEnabled] = useState(true);

  // fires immediately the page loads
  useEffect(() => {
    const savedUsername = localStorage.getItem("username");
    savedUsername && setUsername(savedUsername);
  }, []);

  function removeEmoji(content) {
    let conByte = new TextEncoder("utf-8").encode(content);
    for (let i = 0; i < conByte.length; i++) {
      if ((conByte[i] & 0xF8) == 0xF0) {
        for (let j = 0; j < 4; j++) {
          conByte[i + j] = 0x30;
        }
        i += 3;
      }
    }
    content = new TextDecoder("utf-8").decode(conByte);
    return content.replaceAll("0000", "");
  }

  // save message to gun / send message
  const sendMessage = async () => {
    if (sendEnabled) {
      setSendEnabled(false)
      // the message object to be sent/saved
      const messageObject = {
        sender: username,
        content: messageText,
        timestamp: Date().substring(16, 21),
      };

      // this function sends/saves the message onto the network
      messages.push(messageObject);

      const reply = await fetch(chatResponseUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "same-origin",
        body: JSON.stringify({
          impersonate: "arnas",
          uID: username,
          say: messageText,
          history: messages
        }),
      }).then(async (data) => {
        const response = await data.json();
        return response.response;
      });

      // the message object to be sent/saved
      const robotMessageObject = {
        sender: "Intellicia",
        content: reply,
        timestamp: Date().substring(16, 21),
      };

      // this function sends/saves the message onto the network
      messages.push(robotMessageObject);

      // Set to speak out
      const cleanReply = await removeEmoji(reply)
      props.onTextUpdate(cleanReply)

      // clear the text field after message has been sent
      setMessageText("");
      setSendEnabled(true);
    }
  };

  const saveUsername = async () => {
    localStorage.setItem("username", tempUsername);
    setUsername(tempUsername);
  };

  return username === "" ? (
    <div className="centered">
      <p>What's your name?</p>
      <div className="name-submit">
        <input
          disabled={username !== ""}
          placeholder="Name"
          onChange={(e) => setTempUsername(e.target.value)}
          value={tempUsername}
        />
      </div>
      <button
        className="name-submit"
        disabled={tempUsername === ""}
        onClick={saveUsername}
      >
        Save
      </button>
    </div>
  ) : (
    <div className="Chat">
      {/* <audio ref={audio} src="back.mp3" loop={true} autoPlay={true} type={"audio/mp3"} ></audio> */}
      <main>
        <div className="messages">
          <ul>
            {messages.map((msg, index) => [
              <div key={index}>
                <li key={index} className="message">
                  <Avatar
                    size="30"
                    round={true}
                    name={msg.sender}
                    textSizeRatio={1.75}
                    style={{ padding: "5px" }}
                  />
                  <div>
                    {msg.content}
                    <span style={{ opacity: "0.3" }}>{msg.sender}</span>
                  </div>
                </li>
              </div>,
            ])}
          </ul>
        </div>
        <div className="input-box-wrapper">
          <div className="input-box">
            <div className="input-text">
              <input
                placeholder="Type a message..."
                onChange={(e) => setMessageText(e.target.value)}
                onKeyDown={(e) => {
                  if (e.code === "Enter") {
                    e.preventDefault();
                    sendMessage();
                  }
                }}
                value={messageText}
              />
            </div>
            <button
              className="input-button"
              disabled={messageText.length < 1 && sendEnabled}
              onClick={sendMessage}
            >
              <img src="/send.png" height={17} />
            </button>
          </div>
        </div>
      </main>
    </div>
  );
}

export default Chat;
